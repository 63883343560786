import request from "@/util/request";
import { Order, OrderContent } from "./apiModel";

export function getTotal(): any {
  return request({
    url: `/getordertotal`,
    method: "get",
  });
}

/**
 * 获取订单列表
 * @returns
 */

export interface orderFormModel {
  search: string;
  sort: string;
  pageSize: number;
  pageNum: number;

  updateTimeBegin?: string;
  updateTimeEnd?: string;
  formId?: string;
  phone?: string;
  orderStatus?: string;
  ageBegin?: number;
  ageEnd?: number;
}

export function getOrderList(param: orderFormModel): any {
  let url = "?";
  for (const key in param) {
    if (param[key] != undefined) {
      url += `${key}=${param[key]}&`;
    }
  }
  url = url.substring(0, url.length - 1);
  return request({
    url: `/getorderlist${url}`,
    method: "get",
  });
}

/**
 * 新增订单
 * @param form
 * @returns
 */
export function addOrder(form: Order): any {
  return request({
    url: `/saveorder`,
    method: "post",
    data: { json: JSON.stringify(form) },
  });
}

/**
 * 编辑订单
 * @param form
 * @returns
 */
export function updateOrder(form: Order, content: string): any {
  return request({
    url: `/updateorder`,
    method: "post",
    data: { json: JSON.stringify(form), content: content },
  });
}

/**
 * 根据订单ID获取订单信息
 * @param id
 * @returns
 */
export function getOrderById(id: string): any {
  return request({
    url: `/getorderbyorderid?id=${id}`,
    method: "get",
  });
}

/**
 * 获取内容
 * @param id
 * @returns
 */
export function getContentById(id: string): any {
  return request({
    url: `/getcontentbyorderid?id=${id}`,
    method: "get",
  });
}

/**
 * 编辑内容
 * @returns
 */
export function updateOrderContent(data: OrderContent): any {
  return request({
    url: "/updatecontentbyid",
    method: "post",
    data: data,
  });
}

/**
 * 同步订单
 * @returns
 */
export function syncOrder(form: Order, content: string): any {
  return request({
    url: `/syncData`,
    method: "post",
    data: { json: JSON.stringify(form), content: content },
  });
}
