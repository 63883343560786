
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import {
  MaterialModel,
  Order,
  saveOrderEmit,
  OrderStatusEnum,
} from "@/api/apiModel";
import { getModelById, addNewModel, uploadModel } from "@/api/group";
import {
  getContentById,
  updateOrderContent,
  getOrderById,
  updateOrder,
  getOrderList,
  syncOrder,
  orderFormModel,
} from "@/api/order";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import RichEditor from "@/components/rich-editor.vue";
import EditorToolbar from "@/components/editor-toolbar.vue";
import UserDetail from "@/components/user-detail.vue";
import { useStore } from "vuex";
import {
  AIResult,
  aiLabelUserLabelMap,
  getAIResult,
  getTypeByLabelList,
  labelType,
  replaceAiMould,
  updateAILabels,
  aiPlasticModel,
  aiPlasticEmitModel,
  checkIsImgFace,
} from "@/api/ai";
import { getAllMouldListApi, sendNewLabel } from "@/api/workbench";
import aiLabels from "@/components/aiLabels.vue";

export default defineComponent({
  name: "OperateEditor",
  components: {
    RichEditor,
    EditorToolbar,
    UserDetail,
    aiLabels,
  },
  setup() {
    const route = useRoute();
    const routeQuery = route.query as any; // gid：分组ID:gname:（分组名称）id:（订单或者模板ID） type:mix||workbench,ai(是否AI看脸):true||false
    let isWorkbench = ref(routeQuery.type == "mix" ? false : true);
    let isAI = ref(routeQuery.ai == "true" ? true : false);
    // let faceImage = ref(routeQuery.image);
    let faceImage = ref("");
    let orderId = ref(routeQuery.id);

    let form = ref<MaterialModel>({
      id: routeQuery.id,
      mouldTitle: "",
      mould: "",
      mouldImgName: [],
      mouldType: "图文", // "图片" | "话术" | "图文";
      mouldGroupId: routeQuery.gid,
      mouldGroupName: routeQuery.gname,
    });

    // 获取数据
    let content = ref("");
    let colorContent = ref("");
    let orderUser = ref<Order>({
      id: "",
      jsonstring: "",
      name: "",
      phone: "",
      age: "",
      height: "",
      weight: "",
      faceImgName: [],
      faceImgType: "",
      contentId: "",
      remark: "",
      orderType: "",
      orderStatus: "",
      createUserId: "",
      createtime: "",
      updateUserId: "",
      relatedMsg: "",
    });
    let aiPlasticParam = ref<aiPlasticModel>({
      id: "",
      userId: "",
      imageUrls: [],
    });
    // 获取内容
    let frontFaceImgs = ref<Array<string>>([]);
    const getMaterial = async () => {
      if (routeQuery.id != -1) {
        if (routeQuery.type == "mix") {
          // 模板
          const res = await getModelById(routeQuery.id);
          form.value = res.data;
          content.value = form.value.mould;
        } else {
          // 工作台
          aiLoading.value = true;
          const res = await getContentById(routeQuery.id);
          const order = await getOrderById(routeQuery.id); // 获取基本信息
          orderUser.value = order.data;

          // 检测正脸
          frontFaceImgs.value = orderUser.value.faceImgName.slice(0, 1);
          if (store.state.user.currentGroup != "") {
            frontFaceImgs.value = await checkFrontFace(
              orderUser.value.faceImgName
            );
            if (frontFaceImgs.value.length > 0) {
              faceImage.value = frontFaceImgs.value[0];
            } else {
              faceImage.value = routeQuery.image;
            }
          }
          aiPlasticParam.value = {
            id: orderUser.value.id,
            userId: orderUser.value.phone,
            imageUrls: frontFaceImgs.value, // AI整形照片
          };

          if (res.data == undefined) {
            content.value = "";
          } else {
            content.value = res.data.content;
            colorContent.value = res.data.bgColor;
            bgColor.value = res.data.bgColor;
          }
          aiLoading.value = false;
        }
      } else {
        form.value.mould = "";
        content.value = "";
      }
    };

    const picUpdate = async () => {
      const order = await getOrderById(routeQuery.id); // 获取基本信息
      orderUser.value = order.data;
    };

    let userOriginPic = ref<Array<string>>([]);
    const getUserOriginFace = (pics: Array<string>) => {
      userOriginPic.value = pics.map(
        (x) => "https://facemaster.meb.fit/api/face/getOssImage?fileName=" + x
      );
    };

    // 检测正脸
    const checkFrontFace = async (
      userImgs: Array<string>
    ): Promise<Array<string>> => {
      let result: Array<string> = [];
      const checkRes = await checkIsImgFace(userImgs);
      if (checkRes.Status == "Success" && checkRes.right_face_url != "") {
        result = [
          checkRes.right_face_url.slice(
            checkRes.right_face_url.indexOf("=") + 1,
            checkRes.right_face_url.length
          ),
        ];
      } else {
        ElMessage.error("没有检测到正脸照片,请重新上传");
      }
      return result;
    };

    // 保存模板
    const save = async (content: string) => {
      form.value.mould = content;
      form.value.mouldImgName = [""];
      if (form.value.mouldTitle == "") {
        ElMessage.error("请输入图文名称");
        return;
      }
      let res = null;
      if (routeQuery.id == -1) {
        res = await addNewModel(form.value);
      } else {
        res = await uploadModel(form.value);
      }
      if (res.code == 200) {
        ElMessage.success("操作成功");
      } else {
        ElMessage.error("操作失败" + res.msg);
      }
    };

    // 保存订单内容
    const store = useStore();
    // const userName = computed(() => {
    //   return store.state.user.userName;
    // });
    let autosaveLoading = ref(false);
    const saveOrder = async (value: saveOrderEmit) => {
      if (value.isAutoSave) {
        autosaveLoading.value = true;
      }
      if (value.isSync) {
        syncContent(value.content);
      }
      const res = await updateOrderContent({
        id: routeQuery.id,
        content: value.content,
        bgColor: bgColor.value,
      });
      orderUser.value.orderStatus = OrderStatusEnum.finish;
      // orderUser.value.updateUserId = userName.value;
      const orderRes = await updateOrder(orderUser.value, "");
      setTimeout(() => {
        autosaveLoading.value = false;
      }, 1000 * 2);
      store.commit("SET_ISSAVE", true);
      if (res.code == 200 && orderRes.code == 200) {
        if (!value.isAutoSave) {
          ElMessage.success("保存成功");
        }
      } else {
        ElMessage.error("保存失败");
        return;
      }
      if (value.isNext) {
        let form: orderFormModel = {
          search: "",
          sort: "asc",
          pageSize: 5,
          pageNum: 1,
          orderStatus: "待看",
        };
        const listRes = await getOrderList(form);
        if (listRes.code == 200) {
          if (listRes.data.content.length > 0) {
            // 添加下一个
            const { href } = url.resolve({
              path: `/operateEditor`,
              query: {
                gid: "",
                gname: "",
                id: listRes.data.content[0].id,
                type: "workbench",
              },
            });
            window.open(href, "_self");
          } else {
            ElMessage.warning("没有更多的数据了");
          }
        }
      }
    };
    const changeOrderStatus = async (
      status: OrderStatusEnum
    ): Promise<void> => {
      orderUser.value.orderStatus = status;
      await updateOrder(orderUser.value, "");
    };
    // 导出
    const url = useRouter();
    const exportPdf = async (value: saveOrderEmit) => {
      await saveOrder(value);
      // 导出跳转
      const { href } = url.resolve({
        path: `/export`,
        query: {
          id: orderUser.value.id,
          type: "workbench",
          isExport: "preview",
        },
      });
      window.open(href, "_blank");
    };

    // 同步
    const syncContent = async (content: string) => {
      await updateOrderContent({
        id: routeQuery.id,
        content: content,
      });
      const res = await syncOrder(orderUser.value, content);
      if (res.code == 200) {
        ElMessage.success("同步成功");
      } else {
        ElMessage.error("同步失败");
      }
    };

    let aiLoading = ref(false);
    let isShowAiLabel = ref(false); // 显示AI标签框
    let aiLabelList = ref<Array<string>>(); // AI标签列表
    let allMould = ref();
    let aiRes = ref<AIResult>();

    onMounted(async () => {
      window.onbeforeunload = function (e: any) {
        let el = window.event || e;
        el.returnValue = "确定离开当前页面吗？";
        if (!store.state.editStatus.isSave) {
          if (store.state.editStatus.orderType == "workbench") {
            changeOrderStatus(OrderStatusEnum.wating);
          } else {
            changeOrderStatus(OrderStatusEnum.finish);
          }
        }
      };

      await getMaterial();

      // AI
      if (isAI.value) {
        await aiInit();
        if (isAI.value) {
          if (content.value != "") {
            ElMessageBox.confirm("是否用AI文案替换原有文案", "提示", {
              cancelButtonText: "取消",
              confirmButtonText: "确定",
              type: "warning",
            })
              .then(async () => {
                isShowAiLabel.value = true;
                createAIText(aiRes.value as AIResult, 52, false);
              })
              .catch(() => {
                ElMessage.info("已取消AI替换");
              });
          } else {
            isShowAiLabel.value = true;
            createAIText(aiRes.value as AIResult, 52, false);
          }
        } else {
          ElMessage.error("生成AI报告失败");
        }
      }
      // AI
    });
    const getAllMouldList = async () => {
      const res = await getAllMouldListApi();
      if (res.code == 200) {
        return res.data;
      } else {
        return [];
      }
    };

    // AI初始化
    let originalImg = ref("");
    let originalImgName = ref("");

    const aiInit = async () => {
      ElMessage.warning("AI报告生成中...");
      aiLoading.value = true;
      let aiResAsync;
      try {
        originalImg.value = `https://facemaster.meb.fit/api/face/getOssImage?fileName=${faceImage.value}`;
        originalImgName.value = `${faceImage.value}.jpg`;
        aiResAsync = getAIResult(originalImg.value, originalImgName.value);
      } catch (error) {
        aiLoading.value = false;
        return;
      }
      let allmouldAsync = getAllMouldList();

      aiRes.value = await aiResAsync;
      allMould.value = await allmouldAsync;
      aiLabelList.value = aiRes.value?.Labels;
      aiLoading.value = false;
      if (aiRes.value?.Status == "False") {
        ElMessage.error("AI生成失败：" + aiRes.value.message);
      }
      if (aiRes.value?.gender == -1) {
        ElMessage.error("AI性别判断错误，请重新上传图片");
      }
      if (aiRes.value == undefined) {
        ElMessage.error("没有找到AI报告");
        return;
      }
    };
    // 生成AI文案
    const createAIText = (
      aiRes: AIResult,
      typeId: number,
      isAddAiPlastic: boolean
    ) => {
      if (aiRes.gender == -1) {
        ElMessage.error("AI性别判断错误，请重新上传图片");
        return;
      }
      content.value = "";
      let labelTypeList: Array<labelType> = getTypeByLabelList(aiRes.Labels);

      let mouldByTypeId = allMould.value.filter(
        (x: any) => x.mouldGroupId == typeId
      );

      // 开头语
      let startText = `卢克看脸开头语（${aiRes.gender == 0 ? "女" : "男"}生）`;
      let startMoulds = mouldByTypeId.filter(
        (x: any) => x.mouldTitle == startText
      );
      if (startMoulds.length > 0) {
        content.value += startMoulds[0].mould;
      }
      //

      // 图片
      let randNum = (Math.random() * (99 - 1) + 1).toFixed(0);
      content.value += `<div style="text-align:center;"><image style="max-width: 100%;" src="${aiRes.Label_Image}?random=${randNum}"/></div>`;
      //

      // AI整形
      //（增加AI前后对比图）
      if (isAddAiPlastic) {
        let plasticText = `AI建议${aiRes.gender == 0 ? "女" : "男"}`;
        let mould = mouldByTypeId.filter(
          (x: any) => x.mouldTitle == plasticText
        );
        if (mould.length > 0) {
          content.value += mould[0].mould;
          // content.value += `<div style="text-align:center;"><image style="width: 50%;" src='${aiPlasticRes.value?.picUrl}'></image></div>`;
          content.value += `<div style="text-align:center;"><image style="width: 45%; object-fit: contain;" src='${aiPlasticRes.value?.picUrl}'></image><image style="width: 45%;object-fit: contain;" src='${userOriginPic.value[0]}'></image></div>`;
        }
      }
      //

      // 看脸概括 {face-count},{skin-count}
      let faceLabelCount = labelTypeList.filter((x) => x.type != "skin").length;
      let skinLabelCount = labelTypeList.filter((x) => x.type == "skin").length;
      // let outLineCount = labelTypeList.filter(
      //   (x) => x.type == "outline"
      // ).length;
      let summaryTitle = "卢克看脸整体概括";
      let summaryTexts = mouldByTypeId.filter(
        (x: any) => x.mouldTitle == summaryTitle
      );

      if (summaryTexts.length > 0) {
        let faceReplace = summaryTexts[0].mould.replaceAll(
          "{face-count}",
          faceLabelCount
        );
        let skinReplace = faceReplace.replaceAll(
          "{skin-count}",
          skinLabelCount
        );
        // let outlineReplace = skinReplace.replaceAll(
        //   "{outline-count}",
        //   outLineCount
        // );
        for (let item of aiLabelUserLabelMap) {
          skinReplace = replaceAiMould(
            skinReplace,
            item.code,
            labelTypeList,
            item.user
          );
        }

        content.value += skinReplace;
      }
      //

      // 标签文案
      let labelSexList = aiRes.Labels.map(
        (x) => (x += aiRes.gender == 0 ? "女" : "男")
      );
      let emptyFlag = true;
      for (let item of mouldByTypeId) {
        for (let label of labelSexList) {
          if (label == item.mouldTitle) {
            content.value += item.mould;
            emptyFlag = false;
          }
        }
      }
      if (emptyFlag) {
        ElMessage.warning("暂未匹配到AI报告请手工填写");
      }
      //

      // 结束语
      let endText = `卢克看脸结束语（${aiRes.gender == 0 ? "女" : "男"}生）`;
      let endMoulds = mouldByTypeId.filter((x: any) => x.mouldTitle == endText);
      if (endMoulds.length > 0) {
        let aiReplaceText = endMoulds[0].mould;
        for (let item of aiLabelUserLabelMap) {
          aiReplaceText = replaceAiMould(
            aiReplaceText,
            item.code,
            labelTypeList,
            item.user
          );
        }
        content.value += aiReplaceText;
      }
      //
      // 替换标签
      content.value = content.value.replaceAll("{nose-labels}", "鼻部");
      content.value = content.value.replaceAll("{skin-labels}", "皮肤");
      content.value = content.value.replaceAll("{outline-labels}", "轮廓");
      content.value = content.value.replaceAll("{eye-labels}", "眼睛");
    };

    // 模板类型点击切换
    let labelTypeId = ref(52);
    const getLabelType = (val: number) => {
      labelTypeId.value = val;
      createAIText(aiRes.value as AIResult, labelTypeId.value, false);
    };
    // 标签点击
    const changeAiText = async (newLabels: Array<string>) => {
      if (!isAI.value) {
        ElMessage.warning("非AI看脸不可用AI文案");
        return;
      }
      // 清空
      aiRes.value = {
        Label_Image: "",
        Labels: [],
        gender: 0,
        Status: "Success",
        message: "",
      };
      content.value = "";
      // 发送样本
      sendNewLabel({
        imgUrl: originalImg.value || "",
        originalLabel: aiRes.value?.Labels || [],
        newLabel: newLabels,
      });

      aiLoading.value = true;
      const newAiRes: AIResult = await updateAILabels(
        originalImg.value,
        originalImgName.value,
        newLabels
      );
      aiLoading.value = false;
      createAIText(newAiRes, labelTypeId.value, false);
    };

    let aiPlasticRes = ref<aiPlasticEmitModel>();
    const addAiPlasticText = (param: aiPlasticEmitModel) => {
      aiPlasticRes.value = param;
      createAIText(aiRes.value as any, labelTypeId.value, true);
    };
    //
    let bgColor = ref("");
    const getColor = (value: string) => {
      bgColor.value = value;
    };
    return {
      syncContent,
      form,
      save,
      content,
      isWorkbench,
      saveOrder,
      orderId,
      orderUser,
      exportPdf,
      autosaveLoading,
      changeOrderStatus,
      getColor,
      colorContent,
      aiLoading,
      isShowAiLabel,
      aiLabelList,
      changeAiText,
      getLabelType,
      aiPlasticParam,
      addAiPlasticText,
      picUpdate,
      getUserOriginFace,
    };
  },
});
