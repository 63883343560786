
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { getGroupList, getModelByGroupId, getAllMould } from "@/api/group";
import { GroupModel, MaterialModel } from "@/api/apiModel";
import { useRouter } from "vue-router";
import useClipboard from "vue-clipboard3";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "EditorToolbar",
  props: {
    isWorkbenchProp: {
      type: Boolean,
      requied: true,
    },
    bgColorProp: {
      type: String,
      requied: "",
      default: "",
    },
    relatedMsgProp: {
      type: String,
      requied: false,
      default: "",
    },
  },
  components: {},
  setup(props, context) {
    let isWorkbench = computed(() => {
      return props.isWorkbenchProp;
    });

    let bgColor = computed(() => {
      if (props.bgColorProp != "" && props.bgColorProp != undefined) {
        let colorObj = JSON.parse(props.bgColorProp);
        let title = mouldList.value.filter(
          (x) => x.mouldTitle == colorObj.mouldTitle
        );
        if (title.length > 0) {
          return title[0].mouldTitle;
        }
        return "";
      }
      return "";
    });

    let relatedMsg = computed(() => {
      return convertRelatedMsg(props.relatedMsgProp);
    });

    watch(bgColor, (newVal, _oldVal) => {
      colorSetting.value = newVal;
    });

    /**
     * 关联信息展示
     */
    interface relatedModel {
      name: string;
      desc: string;
      content: string;
      suggest: string;
    }
    const convertRelatedMsg = (msg: string): Array<relatedModel> | null => {
      if (msg == "") {
        return null;
      }
      try {
        let model = JSON.parse(msg);
        console.log("转换模型", model); // test
        let relatedList: Array<relatedModel> = [];
        for (let item of model.solutionList)
          for (let data of item.data)
            relatedList.push({
              name: data.solutionName,
              desc: data.solDesc,
              content: data.analysis,
              suggest: data.suggest,
            });
        return relatedList;
      } catch (e) {
        return null;
      }
    };

    let activeName = ref("img");
    // 复制
    const { toClipboard } = useClipboard();
    const copyContent = async (text: string, type: string) => {
      try {
        // 整合
        switch (type) {
          case "图片":
            text = `<img src='https://facemaster.meb.fit/api/face/getOssImage?fileName=${text}'/>`;
            break;
          case "话术":
            text = `${text}`;
            break;
          case "图文":
            text = `${text}`;
            break;
          default:
            text = `${text}`;
            break;
        }
        //
        await toClipboard(text);
        ElMessage.success("已复制");
      } catch (e) {
        console.log("复制失败：" + e);
      }
    };
    // 获取分组
    let groupAllList = ref<Array<GroupModel>>([]);
    let groupList = ref<Array<GroupModel>>([]);
    const getGroupApi = async () => {
      const res = await getGroupList();
      if (res.code == 200) {
        groupAllList.value = res.data;
      }
    };

    // 标签页切换
    const tabChange = (tab: any) => {
      groupList.value = [];
      for (let i of groupAllList.value) {
        if (i.mouldGroupType == tab.props.label) {
          groupList.value.push(i);
        }
      }
      groupClick(groupList.value[0]);
      mouldList.value = [];
    };

    // 分组选中
    let selectedId = ref("");
    let search = ref("");
    let mouldList = ref<Array<MaterialModel>>([]);
    //搜索
    const searchList = async () => {
      const res = await getAllMould(search.value);
      if (res.code == 200) {
        mouldList.value = res.data;
      }
    };
    const groupClick = async (value: GroupModel) => {
      if (value != undefined) {
        selectedId.value = value.id;
        mouldList.value = [];
        const res = await getModelByGroupId(value.id, search.value);
        if (res.code == 200) {
          mouldList.value = res.data;
        }
      }
    };

    // 内容选中
    const contentClick = (value: MaterialModel, type: string) => {
      if (type == "图片") {
        copyContent(value.mouldImgName[0], type);
      } else {
        copyContent(value.mould, type);
      }
    };

    // 素材管理
    const url = useRouter();
    const manageMaterial = (value: string) => {
      const { href } = url.resolve({
        path: `/material`,
        query: {
          type: value,
        },
      });
      window.open(href, "_blank");
    };

    onMounted(async () => {
      await getGroupApi();
      if (isWorkbench.value) {
        activeName.value = "mix";
        tabChange({ props: { label: "图文" } });
      } else {
        tabChange({ props: { label: "图片" } });
      }
      if (groupList.value.length > 0) {
        await groupClick(groupList.value[0]);
      }
    });

    let colorSetting = ref("");
    const convertCss = (cssStr: string): any => {
      let cssObj = JSON.parse(cssStr);
      let style = {};
      if (cssObj.backgroundType == "pure") {
        style["background-color"] = cssObj.colorList;
      } else {
        style[
          "background-image"
        ] = `linear-gradient(${cssObj.direction},${cssObj.colorList})`;
      }
      style["mouldTitle"] = cssObj.colorName;
      return style;
    };

    const colorChange = (value: any) => {
      let setting = mouldList.value.filter((x) => x.mouldTitle == value)[0]
        .mould;

      let cssStr = convertCss(setting);
      context.emit("getColorSetting", JSON.stringify(cssStr));
      localStorage.setItem("previewColor", JSON.stringify(cssStr));
    };

    return {
      search,
      isWorkbench,
      activeName,
      getGroupApi,
      tabChange,
      groupList,
      selectedId,
      groupClick,
      mouldList,
      contentClick,
      copyContent,
      manageMaterial,
      searchList,
      colorSetting,
      convertCss,
      colorChange,
      bgColor,
      relatedMsg,
      convertRelatedMsg,
    };
  },
});
