import request from "@/util/request-ai";
import requestplastic from "@/util/request-plastic";
import requestImgCheck from "@/util/request-imgCheck";

/**
 * AI看脸返回类型
 */
export interface AIResult {
  Label_Image: string;
  Labels: Array<string>;
  gender: number;
  Status: "Success" | "False";
  message: string;
}

/**
 * 标签列表
 */
const aiLabels = {
  nose: ["鼻头偏大", "鼻基底凹陷", "鼻翼肥大", "短鼻", "塌鼻", "歪鼻", "宽鼻"],
  skin: [
    "法令纹",
    "泪沟",
    "黑眼圈",
    "痤疮",
    "痘印",
    "毛孔粗大",
    "皮肤松弛",
    "色斑",
    "痣",
  ],
  outline: [
    "咬肌偏大",
    "面中凹陷",
    "脸大",
    "咬肌不对称",
    "颧弓外扩",
    "嘴角不对称",
    "眉弓骨扁平",
    "太阳穴凹陷",
    "发际线偏高",
    "下巴后缩",
    "下颌缘模糊",
    "凸嘴",
  ],
  eye: ["多眼皮", "单眼皮", "眼皮松弛", "肿泡眼", "眼睛小", "眼袋", "肌无力"],
  others: ["薄唇", "过敏泛红", "肤色暗沉"],
};

/**
 * AI 标签 用户标签映射
 */
const aiLabelUserLabelMap = [
  { code: "nose", user: "{nose-labels}" },
  { code: "skin", user: "{skin-labels}" },
  { code: "outline", user: "{outline-labels}" },
  { code: "eye", user: "{eye-labels}" },
  { code: "others", user: "{others-labels}" },
];

export interface labelType {
  type: string;
  label: string;
}

/**
 * 根据标签获取标签类型
 * @param label
 * @returns
 */
export function getTypeByLabel(label: string): string {
  let type = "";
  for (const key in aiLabels) {
    for (const item of aiLabels[key]) {
      if (item == label) {
        type = key;
        break;
      }
    }
  }
  return type;
}
/**
 * 标签分类（列表）
 * @param labelList
 * @returns
 */
export function getTypeByLabelList(labelList: Array<string>): Array<labelType> {
  const resultList: Array<labelType> = [];
  for (const item of labelList) {
    resultList.push({ type: getTypeByLabel(item), label: item });
  }
  return resultList;
}

/**
 * AI 填槽
 * @param mould 原始话术文本
 * @param labelType 标签分类 固定： "nose" | "skin" | "outline" | "eye" | "others"
 * @param labelTypeList 所有标签分类列表
 * @param userLabelType 用户在文案上自定义的标签识别（需要识别替换的标签）
 * @returns 填槽后的文案
 */
export function replaceAiMould(
  mould: string,
  labelType: string,
  labelTypeList: Array<labelType>,
  userLabelType: string
): string {
  const labelListBytype = labelTypeList.filter((x) => x.type == labelType);
  if (labelListBytype.length > 0) {
    const labelStr = labelListBytype.map((x) => x.label).toString();
    const result = mould.replaceAll(userLabelType, labelStr);
    return result;
  }
  return mould;
}

/**
 * 获取AI看脸结果
 * @param imageUrl 图片地址
 * @param imgName 图片名称
 * @returns
 */
export function getAIResult(imageUrl: string, imgName: string): any {
  return request({
    url: "/face",
    method: "post",
    data: {
      image_url: imageUrl,
      image_name: imgName,
    },
  });
}

/**
 * 更新标签
 * @param imgUrl
 * @param imgName
 * @param label
 * @returns
 */
export function updateAILabels(
  imgUrl: string,
  imgName: string,
  label: Array<string>
): any {
  return request({
    url: "/label",
    method: "post",
    data: {
      image_url: imgUrl,
      image_name: imgName,
      labels: label,
    },
  });
}

/**
 * ai整形参数
 */
export interface aiPlasticModel {
  id: string;
  userId: string;
  project?: string;
  imageUrls: Array<string>;
}
/**
 * ai整形上传参数
 */
export interface aiPlasticEmitModel {
  picUrl: string;
  labelList: Array<string>;
}
/**
 * 获取整形结果
 * @param param
 * @returns
 */
export function getAiPlaticResult(
  param: aiPlasticModel,
  isCompleteUrl: boolean
): any {
  if (param.project == "眼部") {
    param.project = "双眼皮";
  }
  if (param.project == "鼻部") {
    param.project = "隆鼻";
  }
  return requestplastic({
    url: "/plastic_surgery",
    method: "post",
    data: {
      user_id: param.userId, // 电话
      id: param.id, // 订单编号
      project_name: param.project, // 双眼皮；隆鼻
      image_urls: isCompleteUrl
        ? param.imageUrls
        : param.imageUrls.map(
            (x) =>
              "https://facemaster.meb.fit/api/face/getOssImage?fileName=" + x
          ), // 用户照片
    },
  });
}

/**
 * 判断是否有眼部，鼻部标签
 * @param tagList
 * @returns
 */
export function getTypeNameByTag(tagList: Array<string>): Array<string> {
  const result = [];
  if (tagList.length > 0) {
    for (const item of tagList) {
      if (aiLabels.nose.filter((x) => x == item).length > 0) {
        result.push("鼻部");
      }
      if (aiLabels.eye.filter((x) => x == item).length > 0) {
        result.push("眼部");
      }
    }
    return [...new Set(result)];
  } else {
    return [];
  }
}

/**
 * 检测用户照片是否有正脸
 * @param img
 * @returns
 */
export function checkIsImgFace(img: Array<string>): any {
  const imgUrls = img.map(
    (x) => "https://facemaster.meb.fit/api/face/getOssImage?fileName=" + x
  );
  return requestImgCheck({
    url: "/get_right_face",
    method: "post",
    data: { urls: imgUrls },
  });
}

export { aiLabels, aiLabelUserLabelMap };
