
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from "vue";
import { getOrderById, updateOrder } from "@/api/order";
import { Order } from "@/api/apiModel";
import { uploadPic } from "@/api/group";
import { ElMessage } from "element-plus";
import useClipboard from "vue-clipboard3";

export default defineComponent({
  name: "UserDetail",
  props: {
    orderIdProp: {
      type: Number,
      requied: true,
    },
    isWorkbenchProp: {
      type: Boolean,
      requied: true,
    },
  },
  components: {},
  setup(props, context) {
    let orderId = computed(() => {
      return props.orderIdProp ?? -1;
    });
    let isWorkbench = computed(() => {
      return props.isWorkbenchProp || false;
    });

    let orderMsg = ref<Order>({
      id: "",
      jsonstring: "",
      name: "",
      phone: "",
      age: 0,
      height: 0,
      weight: 0,
      faceImgName: [],
      faceImgType: "",
      contentId: "",
      remark: "",
      orderType: "",
      orderStatus: "",
      createUserId: "",
      createtime: "",
      updateUserId: "",
      appeal: "",
    });
    const getOrder = async () => {
      if (orderId.value != -1 && isWorkbench.value) {
        const res = await getOrderById(orderId.value.toString());
        if (res.code == 200) {
          orderMsg.value = res.data as Order;
          context.emit("getUserFacePic", orderMsg.value.faceImgName);
        } else {
          ElMessage.error("获取用户信息失败" + res.msg);
        }
      }
    };

    // 重新上传
    const { proxy } = getCurrentInstance() as any;
    let isShowDialog = ref(false);
    let uploadLoading = ref(false);
    const reUpload = () => {
      isShowDialog.value = true;
    };
    const reUploadApi = async () => {
      uploadLoading.value = true;
      const file = proxy.$refs.upload.uploadFiles;
      orderMsg.value.faceImgName = [];
      for (let pic of file) {
        let picForm = new FormData();
        picForm.append("file", pic.raw);
        let result = await uploadPic(picForm);
        orderMsg.value.faceImgName.push(result);
      }
      const res = await updateOrder(orderMsg.value, "");
      uploadLoading.value = false;
      if (res.code == 200) {
        ElMessage.success("更新图片成功");
        isShowDialog.value = false;
        getOrderById(orderMsg.value.id);
        context.emit("picUpdate");
        proxy.$refs.upload.uploadFiles = [];
      } else {
        ElMessage.error("更新图片失败");
      }
    };
    const closeDialog = () => {
      proxy.$refs.upload.uploadFiles = [];
      isShowDialog.value = false;
    };
    // 图片点击复制
    const { toClipboard } = useClipboard();
    const copyContent = async (text: string) => {
      try {
        text = `<img src='https://dayu.meb.im/api/face/getOssImage?fileName=${text}'/>`;
        await toClipboard(text);
        ElMessage.success("已复制");
      } catch (e) {
        console.log("复制失败：" + e);
      }
    };

    onMounted(() => {
      getOrder();
    });
    let activeNames = ref("aim");
    return {
      orderId,
      isWorkbench,
      orderMsg,
      reUpload,
      isShowDialog,
      reUploadApi,
      uploadLoading,
      closeDialog,
      copyContent,
      activeNames,
    };
  },
});
