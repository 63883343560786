import request from "@/util/request";

/**
 * 获取看脸订单
 * @param query
 * @returns
 */
export function getOrderList(query: any): any {
  return request({
    url: "",
    method: "get",
    params: query,
  });
}

/**
 * 获取所有素材
 * @returns
 */
export function getAllMouldListApi(): any {
  return request({
    url: "/getmouldlist",
    method: "get",
  });
}

export interface AICorrection {
  imgUrl: string;
  originalLabel: Array<string>;
  newLabel: Array<string>;
}
/**
 * 发送新标签
 * @param data
 * @returns
 */
export function sendNewLabel(data: AICorrection): any {
  return request({
    url: "/label/add",
    method: "get",
    params: {
      imgUrl: data.imgUrl,
      originalLabel: JSON.stringify(data.originalLabel),
      newLabel: JSON.stringify(data.newLabel),
    },
  });
}
