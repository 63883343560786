
import { computed, defineComponent, onMounted, ref } from "vue";
import E from "wangeditor";
import { uploadPic } from "@/api/group";
import { saveOrderEmit, OrderStatusEnum } from "@/api/apiModel";

export default defineComponent({
  props: {
    isWorkbenchProp: {
      type: Boolean,
      requied: true,
    },
    contentProp: {
      type: String,
      requied: true,
    },
  },
  components: {},
  setup: (props, context) => {
    // const url = "http://localhost:8080/mobie-preview.html";
    const url = `${window.location.protocol}//${window.location.host}/mobie-preview.html`;

    let isWorkbench = computed(() => {
      return props.isWorkbenchProp || false;
    });
    let content = computed(() => {
      return props.contentProp || "";
    });

    let editCount = ref(0);
    let richText = ref("");
    // 初始化编辑器
    let editor = ref();
    const initEditor = (): void => {
      editor.value.config.height = 720;
      editor.value.config.placeholder = "请输入内容";
      editor.value.config.zIndex = 500;
      editor.value.config.excludeMenus = ["video"];
      editor.value.config.customUploadImg = async function (
        resultFiles: any,
        insertImgFn: any
      ) {
        let form = new FormData();
        form.append("file", resultFiles[0]);
        let picId = await uploadPic(form);
        insertImgFn(
          `https://facemaster.meb.fit/api/face/getOssImage?fileName=${picId}` // 图片地址
        );
      };
      // 编辑事件
      editor.value.config.onchange = function (newHtml: string) {
        if (editCount.value >= 1) {
          if (isWorkbench.value) {
            context.emit("changeOrderType", OrderStatusEnum.operating);
          }
        }
        editCount.value += 1;
        richText.value = newHtml;
      };
      // 粘贴事件
      editor.value.config.pasteTextHandle = function (pasteStr: string) {
        if (pasteStr.indexOf("getOssImage") > -1) {
          insertHTML(htmlDecode2(pasteStr)); // 图片
        } else {
          if (pasteStr.indexOf("&lt;") > -1) {
            insertHTML(htmlDecode2(pasteStr)); // 图文需要转义
          } else {
            insertHTML(pasteStr); // 话术过来的就不转义
          }
        }
      };
      richText.value = editor.value.txt.html() || content.value; // 初始化文本框
      editor.value.create();
    };
    // 保存模板
    const getRichText = (): void => {
      context.emit("saveMaterial", richText.value);
    };
    // 保存订单
    const saveOrder = (
      isNext: boolean,
      isAutoSave: boolean,
      isSync: boolean
    ): void => {
      context.emit("saveOrderContent", {
        content: richText.value,
        isNext: isNext,
        isAutoSave: isAutoSave,
        isSync: isSync,
      } as saveOrderEmit);
    };
    // 导出
    const exportPdf = (): void => {
      context.emit("exportPdf", {
        content: richText.value,
        isNext: false,
      } as saveOrderEmit);
    };

    // 同步
    const syncContent = () => {
      context.emit("syncContent", richText.value);
    };

    // 光标处插入
    const insertHTML = (value: string | null) => {
      editor.value.cmd.do("insertHTML", value);
    };
    // 取消转义
    const htmlDecode2 = (text: string) => {
      let temp = document.createElement("div");
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      return output;
    };

    onMounted(() => {
      editor.value = new E("#editor");
      initEditor();
      // 调用自动保存
      // setTimeout(() => {
      //   if (isWorkbench.value) {
      //     setInterval(() => {
      //       saveOrder(false, true);
      //     }, 1000 * 15);
      //   }
      // }, 15 * 1000);
    });
    // onUnmounted(async () => {
    //   saveOrder(false, true);
    // });

    // 移动端预览
    let isShowPreview = ref(false);
    const showMobie = () => {
      isShowPreview.value = true;
      localStorage.setItem("mobiePreview", richText.value);
    };
    const clearCache = () => {
      localStorage.clear();
    };
    return {
      content,
      getRichText,
      isWorkbench,
      saveOrder,
      exportPdf,
      syncContent,
      showMobie,
      isShowPreview,
      clearCache,
      url,
    };
  },
});
