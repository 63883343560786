import axios, { AxiosRequestConfig } from "axios";
import { ElMessage } from "element-plus";

const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development" || process.env.NODE_ENV == "stage"
      ? "https://faceapi.meb.fit" // 测试
      : "https://faceapi.meb.fit", // 正式
  timeout: 1000 * 120,
});

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log("AI API ERROR:" + error);
    ElMessage.error("获取AI看脸结果失败，请重新上传图片");
    return Promise.resolve(error);
  }
);

export default service;
